import { useEffect, useState } from "react";
import './App.css';
import BallStage from "./components/Stage";

function App() {

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;
  return (
    <div className="app-container" style={{backgroundImage: isMobile ? "url('./images/space-bg-mobile.webp')" : "url('./images/space-bg.webp')"}}>
      <div className={"stage-wrapper"}>
        <BallStage isMobile={isMobile} />
      </div>
    </div>
  );
}

export default App;

import React, { useEffect, useRef} from "react";
import { Stage, Layer, Circle, Text, Rect, Line, Image } from "react-konva";
import Konva from "konva";
import useImage from "use-image";

const BallStage = ({isMobile}) => {
  const naomiRef = useRef(null);

  const ballRef = useRef(null);
  const ballRef1 = useRef(null);
  const ballRef2 = useRef(null);
  const ballRef3 = useRef(null);
  const ballRef4 = useRef(null);
  const ballRef5 = useRef(null);
  const ballRef6 = useRef(null);

  const treatRef = useRef(null);
  const treatRef1 = useRef(null);
  const treatRef2 = useRef(null);
  const treatRef3 = useRef(null);
  const treatRef4 = useRef(null);
  const treatRef5 = useRef(null);

  const goodDayTextRef = useRef(null);
  const thisIsNaomiTextRef = useRef(null);
  const dreamTextRef = useRef(null);

  const NaomiImage = "/images/naomi-float.png";
  const TennisBallImage = "/images/tennis-ball.png";
  const DogTreatImage = "/images/dog-treat.png";
  const [naomiImage] = useImage(NaomiImage);
  const [tennisBallImage] = useImage(TennisBallImage);
  const [dogTreatImage] = useImage(DogTreatImage);

  const bounceHoc = (element, duration, x, y, rotation = 360, easing = Konva.Easings.EaseInOut()) => {
    return () => {
      element?.current?.to({
        duration,
        x,
        y,
        rotation,
        yoyo: true,
        easing
      })
    }
  }

  const bounceTreat = () => {
    bounceHoc(treatRef, 8, -0.2, window.innerHeight, 360)();
    bounceHoc(treatRef1, 12, -0.8, window.innerHeight / 2, -360)();
    bounceHoc(treatRef2, 6, window.innerWidth * 0.9, window.innerHeight, 360)();
    bounceHoc(treatRef3, 14, window.innerWidth * 0.5, window.innerHeight, 360)();
    bounceHoc(treatRef4, 10, window.innerWidth * 0.1, -0.6, -360)();
    bounceHoc(treatRef5, 7, window.innerWidth * 0.8, window.innerHeight, -360)();

  }


  const bounceBall = () => {
    bounceHoc(ballRef, 12, window.innerWidth, window.innerHeight, 360)();
    bounceHoc(ballRef1, 4, window.innerWidth / 3, -0.3, 360)();
    bounceHoc(ballRef2, 10, 0, window.innerHeight / 3, 360)();
    bounceHoc(ballRef3, 8, window.innerWidth, window.innerHeight / 3, -360)();
    bounceHoc(ballRef4, 5, window.innerWidth / 6, window.innerHeight, 360)();
    bounceHoc(ballRef5, 14, window.innerWidth, window.innerHeight, -360)();
    bounceHoc(ballRef6, 8, window.innerWidth * 0.6, -0.6, 360)();
  }

  const bounceNaomi = () => {
    bounceHoc(
      naomiRef,
      isMobile ? 14 : 12,
      isMobile ? window.innerWidth * 0.8 : window.innerWidth * 0.9,
      isMobile ? window.innerHeight * 1.05 : window.innerHeight / 2.5,
      360)();
  }


  const animateTextHoc = (element, duration, fontSize) => {
    return () => {
      element?.current?.to({
        duration,
        fontSize,
        yoyo: true,
        easing: Konva.Easings.EaseInOut()
      })
    }
  }

  const animateText = () => {
    animateTextHoc(goodDayTextRef, 6, 46);
    animateTextHoc(thisIsNaomiTextRef, 6, 36);
    animateTextHoc(dreamTextRef, 4, 30);
  }


  useEffect(() => {
    bounceBall();
    bounceNaomi();
    bounceTreat();

    if (!isMobile) {
      animateText();
    }
    return () => {

    };
  }, []);

  return (
    <div className={"stage-container"}>
      <Stage width={window.innerWidth} height={window.innerHeight}>

        <Layer>
          <Text
            ref={goodDayTextRef}
            fontFamily={"monospace"}
            text={"Good Day!"}
            x={isMobile ? window.innerWidth * 0.05 : window.innerWidth / 2}
            y={window.innerHeight / 2}
            fill={"white"}
            fontSize={isMobile ? 36 : 40}
            fontStyle={"bold"}
          />

          <Text
            ref={thisIsNaomiTextRef}
            fontFamily={"monospace"}
            text={"This is my dog Naomi."}
            x={isMobile ? window.innerWidth * 0.05 : window.innerWidth / 2}
            y={isMobile ? window.innerHeight / 1.75 : window.innerHeight / 1.8}
            fontStyle={"bold"}
            fill={"white"}
            fontSize={isMobile ? 20 : 34}

          />

          <Text
            ref={dreamTextRef}
            fontFamily={"monospace"}
            text={"This is how I imagine her dreams."}
            x={isMobile ? window.innerWidth * 0.05 : window.innerWidth / 2}
            y={isMobile ? window.innerHeight / 1.6 : window.innerHeight / 1.65}
            fill={"white"}
            fontSize={isMobile ? 18 : 28}
            fontStyle={"bold"}
          />
        </Layer>

        <Layer>
          <Image
            ref={ballRef}
            x={0}
            y={window.innerHeight / 2}
            image={tennisBallImage}
            width={isMobile ? 40 : 60}
            height={isMobile ? 40 : 60}
          />
          <Image
            ref={treatRef3}
            x={window.innerWidth}
            y={0}
            image={dogTreatImage}
            width={isMobile ? 36 : 65}
            height={isMobile ? 20 : 36}
          />

          <Image
            ref={treatRef4}
            x={window.innerWidth * 0.7}
            y={window.innerHeight * 0.9}
            image={dogTreatImage}
            width={isMobile ? 36 : 65}
            height={isMobile ? 20 : 36}
          />

          <Image
            ref={treatRef5}
            x={window.innerWidth * 0.2}
            y={0}
            image={dogTreatImage}
            width={isMobile ? 36 : 65}
            height={isMobile ? 20 : 36}
          />
        </Layer>

        <Layer>
          <Image
            ref={ballRef1}
            x={window.innerWidth / 1.2}
            y={window.innerHeight / 1.1}
            image={tennisBallImage}
            width={isMobile ? 40 : 60}
            height={isMobile ? 40 : 60}
          />

          <Image
            ref={ballRef2}
            x={window.innerWidth / 1.6}
            y={window.innerHeight / 1.2}
            image={tennisBallImage}
            width={isMobile ? 40 : 60}
            height={isMobile ? 40 : 60}
          />
          <Image
            ref={ballRef3}
            x={window.innerWidth / 1.2}
            y={window.innerHeight / 1.7}
            image={tennisBallImage}
            width={isMobile ? 40 : 60}
            height={isMobile ? 40 : 60}
          />

          <Image
            ref={treatRef2}
            x={window.innerWidth / 3}
            y={window.innerHeight / 3}
            image={dogTreatImage}
            width={isMobile ? 36 : 65}
            height={isMobile ? 20 : 36}
          />

          <Image
            ref={ballRef4}
            x={window.innerWidth / 1.6}
            y={0}
            image={tennisBallImage}
            width={isMobile ? 40 : 60}
            height={isMobile ? 40 : 60}
          />

          <Image
            ref={treatRef}
            x={window.innerWidth / 2}
            y={0}
            image={dogTreatImage}
            width={isMobile ? 36 : 65}
            height={isMobile ? 20 : 36}
          />

          <Image
            ref={treatRef1}
            x={window.innerWidth * 0.9}
            y={0}
            image={dogTreatImage}
            width={isMobile ? 36 : 65}
            height={isMobile ? 20 : 36}
          />

          <Image
            ref={ballRef5}
            x={window.innerWidth / 5}
            y={window.innerHeight / 6}
            image={tennisBallImage}
            width={isMobile ? 40 : 60}
            height={isMobile ? 40 : 60}
          />

          <Image
            ref={ballRef6}
            x={window.innerWidth * 0.5}
            y={window.innerHeight * 1.1}
            image={tennisBallImage}
            width={isMobile ? 40 : 60}
            height={isMobile ? 40 : 60}
          />
        </Layer>

        <Layer>
          <Image
            ref={naomiRef}
            x={isMobile ? 0 : 100}
            y={isMobile ? 100 : 200}
            image={naomiImage}
            width={isMobile ? 228 : 320}
            height={isMobile ? 210 : 294}
          />
        </Layer>
      </Stage>
    </div>
  )
}

export default BallStage;
